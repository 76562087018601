const kphToMph = (value) => {
  return Math.round(value * 0.621371);
};

const mphToKph = (value) => {
  return Math.round(value / 0.621371);
};

const roundToNearestMultipleOf5 = (number) => {
    // Round the number to the nearest integer
    const rounded = Math.round(number);
    // Check if the number is divisible by 5
    if (rounded % 5 === 0) {
      // If already a multiple of 5, return the number
      return rounded;
    } else {
      // Round down and up to the nearest multiples of 5
      const down = Math.floor(rounded / 5) * 5;
      const up = Math.ceil(rounded / 5) * 5;
  
      // Choose the nearest multiple of 5
      if (rounded - down < up - rounded) {
        return down;
      } else {
        return up;
      }
    }
  }

export { kphToMph, mphToKph, roundToNearestMultipleOf5 };
