<template>
  <div id="gmap-popin">
    <div id="gmap-popin__header">
      <div>
        <span class="max-w mr-[35px]">
          {{ data.name }}
        </span>
      </div>
      <div @click="close()" class="h-6 w-6 absolute top-3 right-4 z-index-200">
        <XMarkIcon/>
      </div>
    </div>
    <div class="p-4 space-y-4">
      <div class="flex flex-start flex-row">
        <div class="flex flex-col justify-evenly font-bold">
          <p class="mt-[5px]">
            {{ t(`zones_page.table_header.zone_category`) }}
          </p>
          <p class="mt-[5px]">
            {{ t(`zones_page.table_header.speed_limit`) }}
          </p>
          <p class="mt-[5px]">
            {{ t(`zones_page.table_header.status`) }}
          </p>
        </div>
        <div class="flex flex-col justify-evenly font-normal ml-[20px]">
          <p class="mt-[5px]">
            {{ data.category_name }}
          </p>
          <p class="mt-[5px]">
            {{ data.nominal_speed_limit }} {{  data.nominal_speed_limit_unit }} / {{ data.speed_limit_in_kmh }} kmh
          </p>
          <p class="mt-[5px]">
            {{ data.is_active ? 'Active' : 'Inactive'  }}
          </p>
        </div>
      </div>
      <div class="border-t-1 border-lightgray pt-2 pr-4" v-if="route.path.includes('/zones/map') && data.category_slug === userStore.state?.user?.company?.slug">
        <div class="flex items-end justify-between ml-[20px]">
          <button
            @click="confirmDelete(data)"
            class="flex items-start justify-center text-black hover:text-red font-bold "
          >
            <TrashIcon class="h-5 w-5 mr-[3px]" aria-hidden="true"></TrashIcon>
            <p>{{ t(`zones_page.zone.delete.delete`) }}</p>
          </button>
          <a
            :href="`/zones/edit/${data.uuid}`"
            class="text-lg flex text-linkblue hover:text-grayblue font-bold"
          >
            {{ t(`zones_page.table_header.link`) }}
            <ArrowLongRightIcon class="h-7 w-7 inline-block ml-[3px]" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, inject } from "vue";
import { useTranslation } from "../../../composables/lang";
import { useRoute } from "vue-router";
import { XMarkIcon } from "@heroicons/vue/24/solid";
import { ArrowLongRightIcon } from "@heroicons/vue/24/outline";
import { TrashIcon } from "@heroicons/vue/24/solid";

const { t } = useTranslation();
const userStore = inject("userStore");

const emit = defineEmits(["close", "deleteZone"]);

const route = useRoute();

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});
const data = computed(() => props.data);

const close = () => {
  emit("close");
};

const confirmDelete = (data) => {
  emit("deleteZone", data);
}
</script>

<style lang="scss" scoped>
#gmap-popin {
  min-width: 350px;
  position: relative;
  overflow: hidden;
  background-color: #fff;

  @apply flex flex-col rounded shadow-2xl;

  &__header {
    @apply bg-black md:bg-darkgray flex items-center justify-between px-4 py-3;

    p {
      @apply text-white font-bold ;
    }

    div {
      font-size: 20px;

      @apply text-white font-bold cursor-pointer;
    }

    &__timezone {
      font-size: 11px;
    }
  }
}
</style>