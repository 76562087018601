<template>
  <span>
    {{ convertedValue }}
    {{ ["kmh" , "kph"].includes(props.valueUnit) ? "KPH" : "MPH" }}
  </span>
</template>

<script setup>
import { computed } from "vue";
import { kphToMph, roundToNearestMultipleOf5 } from "../../utils/math";
const props = defineProps({
  value: {
    type: [String, Number],
  },
  valueUnit: {
    type: String,
    default: "kmh",
  },
  name: {
    type: String,
  },
});

const convertedValue = computed(() => {
  if(props.name == "posted_speed_limit") {
    return props.valueUnit === "mph" ? roundToNearestMultipleOf5(kphToMph(props.value)) : props.value;
  }

  return props.valueUnit === "mph" ? kphToMph(props.value) : props.value;
});

</script>