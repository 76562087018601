<template>
  <div class="flex flex-wrap items-center mt-4">
    <div
      v-for="item in items"
      :key="item.label"
      class="flex items-center mr-8 mt-4 md:mt-0"
    >
      <img :src="item.icon" alt="" />
      <p class="whitespace-nowrap">{{ t(`main_footer.${item.label}`) }}</p>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRoute } from "vue-router";
import { useTranslation } from "../../composables/lang";
import { LEGENDS } from "../../constants/map.legend";
const route = useRoute();

const { t } = useTranslation();

const keys = Object.keys(LEGENDS);

const key = keys.find(item => route.path.includes(item)) 

const items = ref(LEGENDS[key]);

</script>

<style lang="scss" scoped>
img {
  height: 28px;

  @apply mr-2;
}
</style>