import { post } from "../axios.js"

const get_totp_code = async (data) => {

    //If there is all required datas

    if (data && data.access_token && data.pool_id) {

        const url = `${import.meta.env.VITE_API_BASE_URL}auth/users/attach-software-token`

        try {
            const response = await post({ url, data })
            if (response.data) {
                return response
            } else {
                throw new Error("There is no data available.")
            }
        } catch (err) {
            throw err
        }
    }
    //If there is not all the required datas
    else {
        throw new Error("A required data is missing.")
    }
}

export default get_totp_code