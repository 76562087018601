import { point, polygon, booleanPointInPolygon, } from '@turf/turf';
import USAborders from '../assets/usa-borders.json';

// Define a polygon representing the boundaries of the United States
const NorthAmericPolygon = polygon([
  USAborders
]);

class Base {
  static isInUSACA(lat, lng) {
    const pointToCheck = point([lng, lat]);
    return booleanPointInPolygon(pointToCheck, NorthAmericPolygon);
  }  
}

export { Base };