import { reactive, readonly } from "vue";

const state = reactive({
    isSidenavOpen: true,
    isMobileMenuOpenned: false,
    navigation: [
        {
            name: "Map",
            icon: "map",
            hiddenOnMobile: false,
            children: [
                {
                    name: "Vehicle Locator",
                    to: "MapVehicules",
                    otherRoutes: [],
                    hiddenOnMobile: false
                },
                {
                    name: "Route Analysis",
                    to: "MapRoutes",
                    otherRoutes: [],
                    hiddenOnMobile: true
                },
            ],
        },
        {
            name: "Analytics",
            icon: "analytics",
            hiddenOnMobile: true,
            children: [
                {
                    name: "Overspeed",
                    to: "AnalyticsOverspeedReport",
                    otherRoutes: ["AnalyticsOverspeedStats", "AnalyticsOverspeedMap"],
                    hiddenOnMobile: true
                },
                {
                    name: "Geofences",
                    to: "GeofencesReport",
                    otherRoutes: ["GeofencesMap"],
                    hiddenOnMobile: true
                },
                {
                    name: "Highway Stops",
                    to: "HighwayStopsReport",
                    access: ["admin", "super"],
                    otherRoutes: ["HighwayStopsMap"],
                    hiddenOnMobile: true
                },
            ],
        },
        {
            name: "Vehicle Management",
            icon: "vehicule",
            hiddenOnMobile: false,
            access: ["manager", "admin", "super"],
            children: [
                {
                    name: "Settings",
                    to: "VehiclesSettingsOne",
                    otherRoutes: ["VehiclesSettingsMultiples"],
                    hiddenOnMobile: false
                },
                {
                    name: "Status",
                    to: "VehiclesStatusReport",
                    otherRoutes: ["VehiclesStatusReport", "VehiclesStatusStats"],
                    hiddenOnMobile: true
                },
                {
                    name: "Changes History",
                    to: "ChangesHistory",
                    otherRoutes: [],
                    hiddenOnMobile: true
                },
                {
                    name: "Installations",
                    to: "Installations",
                    hiddenOnMobile: true,
                    access: ["admin", "super"],
                    otherRoutes: [],
                }
            ],
        },
        {
            name: "Zones",
            icon: "zone",
            hiddenOnMobile: true,
            notification: true,
            access: ["admin", "super"],
            children: [
                {
                    name: "Zone List",
                    to: "ZonesList",
                    otherRoutes: ["ZoneList", "ZonesMap", "ZoneCreating", "ZoneEditing"],
                    hiddenOnMobile: true
                },
                {
                    name: "Zone Notifications",
                    to: "ZonesNotificationsListing",
                    otherRoutes: ["ZonesNotificationsListing", "ZonesNotificationsCreating", "ZonesNotificationsEditing"],
                    hiddenOnMobile: true
                },
            ],
        },
        {
            name: "Admin",
            icon: "admin",
            hiddenOnMobile: true,
            access: ["admin", "super"],
            children: [
                {
                    name: "Users",
                    to: "AdminUsers",
                    otherRoutes: ["AdminUsers", "AdminUsersCreate", "AdminUsersEdit",],
                    hiddenOnMobile: true
                },
            ],
        },
    ],
});

const openSidenav = () => {
    state.isSidenavOpen = true
}
const closeSidenav = () => {
    state.isSidenavOpen = false
}
const openMenuMobile = () => {
    state.isMobileMenuOpenned = true
}
const closeMenuMobile = () => {
    state.isMobileMenuOpenned = false
}


export default {
    state: readonly(state),
    openSidenav,
    closeSidenav,
    openMenuMobile,
    closeMenuMobile
};
