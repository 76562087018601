import pinIdle from "../assets/icons/map/pins/pin-idle.svg";
import pinBridge from "../assets/icons/map/pins/bridge.png";
import pinOverspeed from "../assets/icons/map/pins/pin-overspeed.svg";
import pinOverspeedStart from "../assets/icons/map/pins_png/icone-overspeed-start.svg";
import pinRunning from "../assets/icons/map/pins/pin-running.svg";
import PinStopped from "../assets/icons/map/pins/pin-stopped.svg";
import schoolZone from "../assets/icons/map/pins/pin-schoolzone.svg";
import truckSpecific from "../assets/icons/map/pins_png/icone-truck-specific.svg";

// TODO: It is ugly but don't see better way to do it right now
const LEGENDS = {
    "/map/vehicles": [
        {
            label: "Stopped",
            icon: PinStopped,
        },
        {
            label: "Idle",
            icon: pinIdle,
        },
        {
            label: "Running",
            icon: pinRunning,
        },
        {
            label: "SchoolZone",
            icon: schoolZone
        },
        {
            label: "TruckSpecific",
            icon: truckSpecific
        }
    ],
    "/map/routes": [
        {
            label: "Stopped",
            icon: PinStopped,
        },
        {
            label: "Idle",
            icon: pinIdle,
        },
        {
            label: "Running",
            icon: pinRunning,
        },
        {
            label: "SchoolZone",
            icon: schoolZone
        },
        {
            label: "TruckSpecific",
            icon: truckSpecific
        },
        {
            label: "Overspeed",
            icon: pinOverspeed,
        },
        {
            label: "GeoFence",
            icon: pinBridge,
        }
    ],
    "/analytics/overspeed/map": [
        {
            label: "Running",
            icon: pinRunning,
        },
        {
            label: "Overspeed",
            icon: pinOverspeed,
        },
        {
            label: "OverspeedStart",
            icon: pinOverspeedStart,
        },
    ]
}

export { LEGENDS };