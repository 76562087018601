import { point, polygon, booleanPointInPolygon, } from '@turf/turf';
import USAborders from '../assets/usa-borders.json';

// Define a polygon representing the boundaries of the United States
const NorthAmericPolygon = polygon([
  USAborders
]);

class Location {
  constructor(data) {
    this.data = data;
    this.local_time = data.last_tracking?.local_time;
    this.position = {
        lat: data.last_tracking?.latitude,
        long: data.last_tracking?.longitude
    };
    this.time = data.last_event;
    this.type = this._deemType(data);
    this.unit_name = data.unit_name;
    this.timezone = data.last_tracking?.local_timezone;
  }

  static buildLocations(data) {
    const locations = [];
    const positions = data.filter((position) => Location.filterData(position));
    positions.forEach((data) => {
      if (Location.isInUSACA(data.last_tracking?.latitude, data.last_tracking?.longitude)) {
        const location = new Location(data);
        locations.push(location);
      }
    });
    return locations;
  }

  _deemType(data) {
    let vehicleMovment = "stopped";
    if (data.last_tracking?.road_speed_kmh > 0) {
      vehicleMovment = "running";
    }
    // If the value of the road speed from ecm and gnss speed from gnss is less than 0, then the vehicle is stopped.
    if (data.last_tracking?.road_speed_kmh === 0) {
      vehicleMovment = "idle";
    }
    return vehicleMovment;
  }

  static isInUSACA(lat, lng) {
    const pointToCheck = point([lng, lat]);
    return booleanPointInPolygon(pointToCheck, NorthAmericPolygon);
  }

  static filterData(data) {
    return data.last_tracking && data.last_shadow && data.last_tracking.latitude && data.last_tracking.longitude && data.last_tracking.state;
  }
}

export { Location };